import React from 'react';
import { Link } from 'react-router-dom';

import Loadable from 'react-loadable';

import parser from './BBCodeParser';

import decode from 'Utils/encoding/decode';
import fixUrlProtocol from 'Utils/fixUrlProtocol';
import SvgIcon from 'Components/svg/SvgIcon';
import LoaderCss from "Components/common/LoaderCssComponent";

// lazy load these components
const TweetComponent = Loadable( {
	loader: () => import( /* webpackChunkName: "inThread" */ 'Components/posts/tweetComponent' ),
	loading: ( props ) => <LoaderCss lazyLoad={ true } {...props} />,
} );
const LiveStream = Loadable( {
	loader: () => import( /* webpackChunkName: "inThread" */ 'Components/posts/livestreamComponent' ),
	loading: ( props ) => <LoaderCss lazyLoad={ true } {...props} />,
} );
const Quote = Loadable( {
	loader: () => import( /* webpackChunkName: "inThread" */ 'Components/posts/QuoteComponent' ),
	loading: ( props ) => <LoaderCss lazyLoad={ true } {...props} />,
} );
const Image = Loadable( {
	loader: () => import( /* webpackChunkName: "inThread" */ 'Components/common/ImageComponent' ),
	loading: ( props ) => <LoaderCss lazyLoad={ true } {...props} />,
} );

function convertCodeBlock(codeBlockObject, props={}) {
	let style = {};
	let { open: { tag, attributes, params }, content, close } = codeBlockObject;

	switch( tag ) {
		case 'quote':
			let u, u2, username = '', id = 0;

			if( typeof params === 'string' ) {
				// getting who's quoted
				// let's ignore the 0 element, username is 1 and post id 1
				[ , u, id = 0, u2 ] = params.match( /([^]+);(\d+)|([^]+)/i );
				// with post or without?
				username = u || u2;
			}
			if( content ) {
				return (
					<Quote key={ 'quote_' + key_counter }
						postId={ parseInt( id ) }
						userName={ username }
						posts={ props.posts || {} }
						users={ props.users || {} }>
						{ recurseConvert( content, props ) }
					</Quote>
				);
			} else {
				return `[${tag}${params || ''}]`;
			}

		case 'list':
			if( params ) {
				let listType = '';
				switch( params ) {
					case 'A':
						listType = 'upper-alpha';
						break;
					case 'a':
						listType = 'lower-alpha';
						break;
					case 'I':
						listType = 'upper-roman';
						break;
					case 'i':
						listType = 'lower-roman';
						break;
					case '1':
					default:
						listType = 'decimal';
						break;
				}
				return <ol key={ key_counter } style={ { listStyleType: listType } }>{ recurseConvert( cleanExtraCR( content ), props ) }</ol>;
			} else {
				return <ul key={ key_counter }>{ recurseConvert( cleanExtraCR( content ), props ) }</ul>;
			}

		case 'email':
			return <a key={ key_counter } href={ `mailto:${ params || content }` } data-rel="external">{ recurseConvert( content, props ) }</a>;

		case 'code':
		case 'html':
		case 'php':
			// if we modify these types here we need to update BBCodeParser.js->preformatted RegExp
			let languages = [ 'html', 'php' ],
				codeClassName = languages.some( l => l === tag ) ? `language-${ tag }` : '';
			return <pre className="code nolinks nooptimize norewrite" key={ key_counter }><code className={ codeClassName }>{ decode( content ) }</code></pre>;

		case 'justify':
		case 'center':
		case 'right':
		case 'left':
			return <p key={ key_counter } className={ `text-align-${tag}` }>{ recurseConvert( content, props ) }</p>;

		case 'size':
			// Let's calculate the base font size depending on user settings
			let baseSize = 1, // default normal
				appContainer = document.getElementById( 'app' ),
				sizeSet = appContainer ? appContainer.classList : false;

			switch( !!sizeSet ) {
				case sizeSet.contains( 'fontSize-small' ):
					baseSize = 0.85;
					break;
				case sizeSet.contains( 'fontSize-big' ):
					baseSize = 1.35;
					break;
				case sizeSet.contains( 'fontSize-huge' ):
					baseSize = 1.55;
					break;
			}

			let givenSize = parseInt( params );
			style = givenSize < 8 ?
				{ fontSize: baseSize + ( 0.10 * ( givenSize <= -7 ? -7 : givenSize ) ) + 'rem' } : // limit the lower
				{ fontSize: ( givenSize > 42 ? 42 : givenSize ) + 'px' }; // cap on 42px
			return <span key={ key_counter } style={ style } >{ recurseConvert( content, props ) }</span>;

		case 'font':
			return <span key={ key_counter } >{ recurseConvert( content, props ) }</span>;

		case 'indent':
			return <span key={ key_counter } className="text-indented">{ recurseConvert( content, props ) }</span>;

		case 'url':
			 // if the url is a video embed it
			// props.skipUrlVideos !== true will force to insert a regular link [ see ame tag ]
			let video_data = params && params.length && props.skipUrlVideos !== true ? is_embed_video( params ) : {};
			if( props.skipUrlVideos !== true && !video_data.type ) {
				video_data = is_embed_video( content[ 0 ] );
				if( video_data ) {
					codeBlockObject.open.params = video_data.type;
					codeBlockObject.content = ( typeof content !== 'string' && typeof content[ 0 ] === 'string' ) ? content[ 0 ] : content;
				}
			}

			if( video_data.type ) {
				codeBlockObject.open.tag = 'video';
				return <div key={ `video_${ key_counter }` }>
					<em key={ `videotitle_${ key_counter }` } className="videoembed_title">{ recurseConvert( codeBlockObject.content, { ...props, skipUrlVideos: true } ) }</em>
					{ convertCodeBlock( codeBlockObject, props ) }
				</div>;

			} else {
				let href = ( typeof params === 'string' ? params : content[ 0 ] );
				if( typeof href === 'string' ) {// if href is not a string then probably we have a malformed code, skip <a>
					href = href.replace( /(^&quot;)|(&quot;$)/ig, '');
					return <a key={key_counter} href={ href } rel={ relAnchor( href, true ) } target="_blank">{recurseConvert( content, props )}</a>;
				} else {
					return recurseConvert( content, props );
				}
			}

		case 'ame':
			if( typeof params === 'string' && /youtu(?:be\.com|\.be)/i.test( params ) ) {
				// props.skipUrlVideos !== true makes skip conversion url -> embed video duplicating most of the [ame]
				let title = typeof content === 'string' || typeof content[0] === 'string' ? content : recurseConvert( content, { ...props, skipUrlVideos: true } );
				return <div key={ `ame_${key_counter}` } ><em className="videoembed_title">{ decode( title ) }</em>
					{ embed_video( params, 'youtube' ) }
					</div>;
			}

			if( typeof content !== 'string' && typeof content[0] === 'string' && /youtu(?:be\.com|\.be)/i.test( content[0] ) ) {
				return embed_video( content[0], 'youtube' );
			}

			if( params && params.length > 0 && content ) {
				return <a href={ params } rel={ relAnchor( params, true ) } target="_blank" key={ key_counter }>{ recurseConvert( content, props ) }</a>;
			}

			return embed_video( content );

		case 'video':
			let v_data = { params: params, type: undefined, videoId: null };
			v_data = is_embed_video( params );

			if( !v_data.type ) {
				v_data = is_embed_video( content );
			}
			return embed_video( v_data.params, v_data.type, v_data.videoId );

		case 'youtube':
		case 'youtubehd':
		case 'youtube_browser':
			return embed_video( content, 'youtube', ( content.length && content[ 0 ].indexOf( ':' ) < 0 ? content[ 0 ] : null ) );

		case 'dm': // dailymotion
		case 'metacafe': // metacafe
		case 'vimeo': // vimeo
		case 'soundcloud': // SoundCloud
			return embed_video( content, tag );

		case 'tweet': // twitter
			let tweetid = content[0];

			if( content[ 0 ].indexOf( '://' ) >= 0 ) {
				let newtweetid = /\d+$/.exec( tweetid );
				tweetid = newtweetid[ 0 ];
			}

			let tweetElementsId = `tweet_${ tweetid }_${ Date.now() }${ Math.floor( Math.random() * 1000 ) }`;
			return <TweetComponent key={ key_counter } tweetid={ tweetid } tweetElementsId={ tweetElementsId } />;

		case 'thread':
		case 'post': // internal links
			let type = tag === 'post' ? `0?postid=` : '',
				itemId = params ? params : content[ 0 ] ;
			return <Link key={ key_counter } to={ `/topics/${ type }${ itemId }` } className="link">{ content }<SvgIcon size="16px" icon="link" /></Link>;

		case 'gs': // Google Spreadsheet
			return <a key={ key_counter } href={ `https://spreadsheets.google.com/pub?key=${ content }&hl=en&single=true&gid=0&output=html` } rel="noopener nofollow" target="_blank">Open this Spreadsheet.</a>;

		case 'gd': // Google Document
			return <a key={ key_counter } href={ `https://docs.google.com/document/pub?id=${ content }` } rel="noopener nofollow" target="_blank">Open this Google Document.</a>;

		case 'gp': // Google Presentation
			return <a key={ key_counter } href={ `https://docs.google.com/present/view?id=${ content }` } rel="noopener nofollow" target="_blank">Open this Presentation.</a>;

		case 'attach':
		case 'attachment':
			let attachmentId = params && isFinite( params ) ? params : ( content.length ? content[0] : content ),
				attachment = isFinite( attachmentId ) && props.items && props.items[ props.postId ] ? props.items[ props.postId ].find( ( a ) => parseInt( a.id ) === parseInt( attachmentId ) ) : null,
				attachmentSrc = attachment && Object.keys( attachment ).length ? attachment.thumbnail || attachment.src : null ;

			return attachmentSrc ?
				<Image key={ key_counter } src={ fixUrlProtocol( attachmentSrc ) } /> :
				<span key={ key_counter }>{ `[${ tag }${ params ? '=' + params : ''}]`}<span key={ key_counter }>{ recurseConvert( content, props ) }</span>{ `[${close.tag}]` }</span>;

		case 'iurl':
			return <span key={ key_counter } className="attachment_incontent">
				<a key={ 'a_' + key_counter } href={ fixUrlProtocol( params ) } rel={ relAnchor( params, true ) } target="_blank">
					{ recurseConvert( content, props ) }
				</a>
			</span>;

		case 'img':
		case 'imgnoism': // mark img as no-ad
			let className = attributes.class || '', // get classes and concat them
				inlineImage = /inlineimg/.test( className ) || /\/smilies\//.test( content[0] ), // is inline?
				skipLazyLoad = /noLazyLoad/.test( className ),
				// get sizes: they can be in params wxh or in attributes width and height
				imgSize = params && params.match( /(\d+)x(\d+)/ ) || { width: attributes.width || null, height: attributes.height || null  },
				imgWidth = parseInt( imgSize[ 1 ] ) || parseInt( imgSize[ 'width' ] ) || null,
				imgHeight = parseInt( imgSize[ 2 ] ) || parseInt( imgSize[ 'height' ] ) || null;
			// adding class no-ad if needed
			className += tag === 'imgnoism' ? ' no-ad' : '';

			if( skipLazyLoad ) {
				return <img key={key_counter} src={content[ 0 ]} className={className} width={imgWidth} height={imgHeight} />
			} else {
				return <Image key={key_counter} src={content[ 0 ]} inline={inlineImage} className={className} width={imgWidth} height={imgHeight} />;
			}

		case 'noviglink': // No Viglink content
			return <div key={ key_counter } className="p nolinks">{ recurseConvert( content, props ) }</div>;

		case 'nointellitxt': // No Vibrant
			return <span key={ key_counter } id="nointelliTXT">{ recurseConvert( content, props ) }</span>;

		case 'info':
			return <span key={ key_counter } className="info"><SvgIcon size="20px" icon="info" />{ recurseConvert( content, props ) }</span>;
		case 'warn':
			return <span key={ key_counter } className="warning"><SvgIcon size="20px" icon="warning" />{ recurseConvert( content, props ) }</span>;

		case 'color':
			style = { color: params };
			return <span key={ key_counter } style={ style }>{ recurseConvert( content, props ) }</span>;

		case 'highlight':
			return <span key={ key_counter } className="highlight">{ recurseConvert( content, props ) }</span>;

		case 'mention':
			// Link to user profile - will show the @user
			return <b key={ key_counter } className={'mention'}>
					@<Link key={ `link_${ key_counter }` } to={ `/userprofile/${ params }` } className="link" rel="nofollow">{ Array.isArray( content ) ? content.join(' ') : content }</Link>
				</b>;

		case 'scroll':
			return <marquee key={ key_counter }>{ recurseConvert( content, props ) }</marquee>;

		case 'i':
		case 'em':
			return <em key={ key_counter }>{ recurseConvert( content, props ) }</em>;
		case 'b':
			return <b key={ key_counter }>{ recurseConvert( content, props ) }</b>;
		case 's':
			return <del key={ key_counter }>{ recurseConvert( content, props ) }</del>;
		case 'sub':
			return <sub key={ key_counter }>{ recurseConvert( content, props ) }</sub>;
		case 'u':
			return <span key={ key_counter } style={ { textDecoration: 'underline' } }>{ recurseConvert( content, props ) }</span>;
		case 'sup':
			return <sup key={ key_counter }>{ recurseConvert( content, props ) }</sup>;

		case 'ol':
			return <ol key={key_counter}>{recurseConvert( cleanExtraCR( content ), props )}</ol>;
		case 'ul':
			return <ul key={key_counter}>{recurseConvert( cleanExtraCR( content ), props )}</ul>;
		case 'li':
			return <li key={key_counter}>{recurseConvert( content, props )}</li>;

		case 'table':
			return <table key={key_counter}><tbody>{recurseConvert( cleanExtraCR( content ), props )}</tbody></table>;
		case 'tr':
			return <tr key={key_counter}>{recurseConvert( cleanExtraCR( content ), props )}</tr>;
		case 'th':
			return <th key={key_counter}>{recurseConvert( content, props )}</th>;
		case 'td':
			return <td key={key_counter}>{recurseConvert( content, props )}</td>;

		case 'livestream':
			if( content && content.length > 0 ) {
				return <LiveStream content={ content[0] } key={ key_counter } />;
			}
			return null;

		case 'spoiler':
			return <div className="spoilerAlert" key={ key_counter } >
						<p>Spoiler Alert <em onClick={ (e) => showSpoiler(e) }>Show</em></p>
						<div className="spoiler-message hidden">{ recurseConvert( content, props ) }</div>
					</div>;

		case 'noTag':
			return <span key={ key_counter } >{ recurseConvert( content, props ) }</span>;

		default:
			// rebuild the attibutes in pairs key="value"
			let attr = Object.entries( attributes ).map( pair => pair.length > 1 ? `${ pair[ 0 ] }="${ pair[ 1 ] || '' }"` : '' ).join( ' ' );
			if( close ) {
				return <span key={ key_counter } >
					{ `[${tag}${ params ? '=' + params : ''} ${attr || ''}]` }
					<span key={ key_counter } >{ recurseConvert( content, props ) }</span>
					{ `[/${close.tag}]` }
				</span>;
			} else {
				return <span key={ key_counter } >{ `[${tag}${ params ? '=' + params : ''} ${attr || ''}]` }</span>;
			}
	}
}

let key_counter = 0,
	allowHTML = false,
	last_err = null;

function recurseConvert( parseArray, props ) {
	let sub_key_counter = 0;
	return ( Array.isArray( parseArray ) ? parseArray : [ parseArray ] ).map( e => {
		key_counter++;
		if( Array.isArray( e ) ) {
			recurseConvert( e, props );

		} else if( typeof e === 'string' ) {
			if( e ) {
				sub_key_counter++;
				// Try to decode or raw on URIError
				let e_URIdecoded = e;
				try {
					e_URIdecoded = decodeURIComponent( e )
				} catch( err ) { /* ingore it */ }

				// We dangerously set innerhtml here because the plugin pre-sanitizes the content
				return <span key={ key_counter + '_' + sub_key_counter } dangerouslySetInnerHTML={ { __html: ( allowHTML ? decode( e ) : e_URIdecoded ).replace( /(?!<br[^\/]*>)\n{2,3}/ig, '<br />\n') } }/>;
			}
			return false;

		} else if( e ) {
			if( e.content || e.open.params || Object.keys( e.open.attributes ).length ) { // convert if we have content or attributes
				return convertCodeBlock( e, props );

			} else if( e.open.tag ) { // malformed tags? let's leave them
				let _tag = '[' + e.open.tag + ']'; // recreate the tag
				return <span key={ key_counter } dangerouslySetInnerHTML={ { __html: _tag } }/>;
			}
		}
	});
}

// Cleans extra carriage returns between elements
function cleanExtraCR( content ) {
	let re = /^[\n|\r]+\s*$/;
	return content ? content.filter( e => !( typeof e === 'string' && re.test( e ) ) ) : [];
}

// Checks for potential embed videos
function is_embed_video( content ) {
	if( !content ) {
		return false;
	}

	// Note reg: regular expresions matching element 1
	let videoSites = {
		youtube: { reg: '(?:(youtu(?:\\.?)be)(?:\\.com)?\\/(?:watch\\?v=)?([a-z0-9-_]+))', type: 'youtube' },
		soundcloud: { reg: '(?:(soundcloud)\\.com\\/player\\/[?]url=(.+))', type: 'soundclooud' },
		vimeo: { reg: '(?:player\\.(vimeo)\\.com\\/video\\/(.+))|(?:(vimeo)\\.com\\/(\\d+))', type: 'vimeo' },
		metacafe: { reg: '(?:(metacafe)\\.com\\/embed\\/(.+))', type: 'metacafe' },
		dailymotion: { reg: '(?:(dailymotion)\\.com\\/embed\\/video\\/(.+))', type: 'dm' },
		dm: { type: 'dm' }
	};

	if( typeof content === 'object' ) {
		content = content.length > 0 ? content[0] : '';
	}

	// We can search for an URL or <type;id>
	let [ videoType, videoId ] = content.indexOf( ';' ) < 0 ? [ null, null ] : content.split( ';' );

	if( videoType && videoId ) {
		if( videoSites[ videoType ] ) {
			return {
				params: content,
				type: videoSites[ videoType ].type,
				videoId: videoId
			};
		}
	} else {
		//
		let re = '';
		for( let type in videoSites ) {
			if( videoSites[ type ].reg ) {
				re += (re ? '|' : '') + videoSites[ type ].reg
			}
		}

		re = new RegExp( re, 'i' );
		let videoInfo = content.match( re );
		if( videoInfo && videoInfo.length >= 3 ) {
			// we only want the values not undefined
			let v=[];
			videoInfo.map( i => i ? v.push( i ) : undefined );
			return {
				params: content,
				type: videoSites[  v[ 1 ].replace( '.', '' ) ].type,
				videoId: v[ 2 ] || null
			};
		}
	}

	return false;
}

// video embeding or not
function embed_video( content, type = false, videoId = null ) {
	let videoType = null;

	if( type && videoId ) { // we have everything we need
		videoType = type.toLowerCase();
		content = videoId;

	} else if( typeof content === 'object' ) {
		content = content.length > 0 ? content[0] : '';
	} else if( content.indexOf( ';' ) >= 0 ) {
		[ videoType, content ] = content.indexOf( ';' ) < 0 ? [ null, null ] : content.toLowerCase().split( ';' );
	}

	switch( type ) {
		case 'dm': // dailymotion
			return <iframe key={ key_counter } className="videoembed" src={ `https://www.dailymotion.com/embed/video/${content}` } frameBorder="0" />;

		case 'metacafe': // metacafe
			return <iframe key={ key_counter } className="videoembed" src={ `https://www.metacafe.com/embed/${content}` } allowFullScreen frameBorder="0" />;

		case 'vimeo': // vimeo
			return <iframe key={ key_counter } className="videoembed" src={ `https://player.vimeo.com/video/${content}` } frameBorder="0" allowFullScreen />;

		case 'soundcloud': // SoundCloud
			return <iframe key={ key_counter } className="videoembed" scrolling="no" frameBorder="no" src={ `https://w.soundcloud.com/player/?url=${ encodeURIComponent( content ) }&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&visual=true`} />;

		case 'youtube':
		default:
			// is a youtube video?
			let _ym = content.match( /youtu(?:be\.com|\.be)\/(?:watch\?v=)?([a-z0-9\-\_]+)/i );
			if( ( _ym && _ym[ 1 ] ) || videoType === 'youtube' ) {
				let _youtube = 'https://www.youtube.com/embed/' + ( videoType ? content : _ym[ 1 ] );
				return <iframe key={ key_counter } className="videoembed" src={ _youtube } frameBorder="0" allowFullScreen />;

			} else {
				return <a key={ key_counter } href={ content } rel="noopener nofollow" target="_blank">{ content }</a>;
			}
	}
}

function showSpoiler( e ) {
	e.stopPropagation();

	e.target.classList.add( 'hidden' );
	e.target.parentNode.nextSibling.classList.remove( 'hidden' );
	return true;
}

// location hostname regex
const locationRegex = new RegExp( `${ location.hostname.split( '.' ).join( '\\.' ) }`, 'i' );
// checks what rel attribute value must be: external link add 'nofollow'
function relAnchor( href, noOpener ) {
	// if requested => noopener
	// if external link => nofollow
	return ( `${noOpener ? 'noopener' : ''} ${ locationRegex.test( href ) ? '' : 'nofollow' }` ).trim();
}

function initParser( rawBody, props = {}, html = false ) {
	try {
		let parsed = parser.tryParse( rawBody );

		key_counter = 0;
		allowHTML = html;
		last_err = null;

		return recurseConvert( parsed, props );

	} catch( err ) {
		key_counter++;
		topifyConsole.error( 'Parsing error!!!', err );

		if( err.result ) {
			// sometimes we have missing [/?] tags or a string like '[/',
			// replace the '/' with entities and try again
			// only one attempt per issue
			let { expected, index } = err.result;
			if(
				expected.length
				&& last_err !== index.offset
				&& ( rawBody.charAt( index.offset ) === '/' || rawBody.charAt( index.offset - 1 ) === '/' )
			) {
				// save last error position
				last_err = index.offset;
				let offset = rawBody.charAt( index.offset - 1 ) === '/' ? 1 : 0;

				// replace the problematic / => '' (&#x2;)
				// &#x2; => https://www.fileformat.info/info/unicode/char/0002/index.htm
				rawBody = rawBody.substring( 0, index.offset - offset ) + '' + rawBody.substring( index.offset - offset );
				return initParser( rawBody, props, html );
			}

			topifyConsole.error( "Failed to parse, expected:", expected, " at index", index );
			topifyConsole.log( rawBody.slice( (index.offset > 100 ? index.offset - 100 : 0), index.offset + 200 ) );
		}

		last_err = null;
		return <span key={key_counter}>{rawBody}</span>;
	}
}

export default function( rawBody, props = {}, html = false ) {
	return initParser( rawBody, props, html );
}