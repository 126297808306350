import { normalize, schema } from 'normalizr';

import { receiveCurrentUser } from './UstateActions';

let theRequestedPage = { type: '', page: 0 };

export function responseNormalizer( json, requestPage, other = {} ) {
	/* Schemas */
	const user = new schema.Entity( 'users' );
	const forum = new schema.Entity( 'forums' );
	const topic = new schema.Entity( 'topics', { forumUser: user, forum: forum } );
	const sticky = new schema.Entity('sticky', { forumUser: user, forum: forum });
	const announcement = new schema.Entity('announcements');
	const pollOption = new schema.Entity( 'pollOptions' );
	const pollVote = new schema.Entity( 'pollVotes' );
	const poll = new schema.Entity( 'polls', { pollOption: pollOption, pollVote: pollVote } );
	const post = new schema.Entity( 'posts', { forumUser: user, topic: topic } );
	const attachment = new schema.Entity( 'attachments', { forumUser: user, post: post } );
	const pmfolder = new schema.Entity( 'pmfolders' );
	const pm = new schema.Entity( 'pms', { pmfolder: pmfolder, forumUser: user, toUser: [ user ] } );
	const online = new schema.Entity('online');
	const page = new schema.Entity( 'page' );
	const notice = new schema.Entity( 'notices' );

	// Pre-normalizing: getting the data
	let preNormalized = {
		users: ( json.forumUser || { data: [] } ).data,
		forums: addPagination( requestPage, json, 'forum' ),
		topics: addPagination( requestPage, json, 'topic' ),
		sticky: ( json.sticky || { data: [] } ).data,
		announcements: ( json.announcement || { data: [] } ).data,
		posts: addPagination( requestPage, json, 'post' ),
		polls: ( json.poll || { data: [] } ).data,
		pollOptions: ( json.pollOption || { data: [] } ).data,
		pollVotes: ( json.pollVote || { data: [] } ).data,
		attachments: ( json.attachments || { data: [] } ).data,
		pmfolders: ( json.pmfolder || { data: [] } ).data,
		pms: addPagination( requestPage, json, 'pm' ),
		online: addPagination( requestPage, json, 'online' ),
		portalpage: ( json.portal || { data: [] } ).data,
		notices: ( json.notices || { data: [] } ).data
	};

	// clean
	theRequestedPage = { type: '', page: 0 };

	// Building the result with normalizr
	return Object.assign(
		{},
		other,
		normalize(
			preNormalized,
			{
				users: [ user ],
				forums: [ forum ],
				topics: [ topic ],
				sticky: [ sticky ],
				announcements: [ announcement ],
				posts: [ post ],
				polls: [ poll ],
				pollOptions: [ pollOption ],
				pollVotes: [ pollVote ],
				attachments: [ attachment ],
				pmfolders: [ pmfolder ],
				pms: [ pm ],
				online: [ online ],
				portalpage: [ page ],
				notices: [ notice ]
			}
		)
	);
}

export function updateUstate( json, result, skipNotifications = false ) {
	return function( dispatch ) {
		if( json.ustate ) {
			// Do we have notifications? update counter
			if( !skipNotifications && json.notifications && json.notifications.data ) {
				json.ustate.notifications = {
					pms: json.notifications.data[ 0 ].pms || 0
				};
			}

			let new_ustate = json.ustate;
			// Get the current user info if we have it or null the fields
			try {
				let currentUser = result.entities.users[ new_ustate.id ];
				new_ustate = Object.assign( {}, new_ustate, {
					userName: currentUser.userName,
					avatar: currentUser.avatar,
					online: currentUser.online,
					lastActivity: currentUser.lastActivity
				} );
			} catch( e ) {
				new_ustate = Object.assign( {}, new_ustate, {
					userName: null,
					avatar: null,
					online: null,
					lastActivity: null
				} );
			}

			// Update the state
			dispatch( receiveCurrentUser( new_ustate ) );
		}
	};
}

/**
 * Add to all elements the page it belongs to
 *
 * @param requestPage
 * @param elements
 * @param type
 * @returns {Array}
 */
function addPagination( requestPage, elements, type ) {
	if( !type || !elements[ type ] || !elements[ type ].data ) {
		return [];
	}

	// Adding requested page to all those elements
	// that potentially can be paginated
	return elements[ type ].data.map( e => {
		return Object.assign( e, { page: checkRequestedPage( requestPage, elements, type ).page || 1 } );
	} );
}

/**
 * When we ask for an unknown page (last read pos., postID...)
 * we'll get the page on the meta object in the response
 * Let's find out or fallback to 1
 *
 * @param requestPage
 * @param elements
 * @param type
 * @returns {{type: string, page: number}}
 */
function checkRequestedPage( requestPage, elements, type ) {
	if( theRequestedPage.type === type && theRequestedPage.page ) {
		return theRequestedPage;
	}

	// If we are on a search, following or userprofile we'll use this meta
	let meta = elements[ type ].meta; // default to type meta
	switch( true ) {
		case !!( elements.following && elements.following.meta ): // are we on following
			meta = elements.following.meta;
			break;

		case !!( elements.search && elements.search.meta ): // are we on search
			meta = elements.search.meta;
			break;

		case !!( elements.forumUser && elements.forumUser.meta ): // are we on user profile
			meta = elements.forumUser.meta;
			break;
	}

	// Clean elements unwanted in meta
	delete meta.lb; // loading behaviour
	delete meta.allowhtml; // allowhtml will be in threadview not in meta

	try {
		requestPage = ( meta ? parseInt( meta.page ) : false ) || 1;
	} catch( e ) {
		requestPage = 1;
	}

	// Let's check if the requested page is bigger than totalPages
	try {
		let maxPage = ( meta ? parseInt( meta.totalPages ) : false ) || 1;
		requestPage = maxPage >= requestPage ? requestPage : maxPage;
	} catch( e ) {}

	// Save it
	theRequestedPage = { type: type, page: requestPage || 1 };

	return theRequestedPage;
}